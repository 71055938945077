import React from 'react'

class NewsItem extends React.PureComponent {
  render() {
    const { newsitem } = this.props
    return (
      <div className='news-item'>
        <div className='img-box'>
          <a
            href={newsitem.link || `/news-post/${newsitem.id}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              alt='example'
              src={`${process.env.STRAPI_URL}${newsitem.img.url}`}
              className='news-img'
            />
          </a>
        </div>
        <div className='text-con'>
          <div className='tit'>
            <a
              href={newsitem.link || `/news-post/${newsitem.id}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {newsitem.title}
            </a>
          </div>
          <div className='date'>{newsitem.publish_date}</div>
          <div className='tags'>
            {(newsitem.tags || []).map(tag => (
              <span key={tag.id}>{tag.key}</span>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default NewsItem
